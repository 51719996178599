import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import {
  DemoTractorForm,
  DemoImplementForm,
  InboundForm,
  Inboundtractor,
  InboundImplements,
  Plantix,
  PlantixTractor,
  PlantixImplement,
  Noc,
  NocTractor,
  NocImplement,
  DefaultForm,
  DefaultTractor,
  DefaultImplement,
  LeadsImplementForm,
  LeadsTractorForm
} from "../screens";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 5em)",
  },
}));

const Routes = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path="/">
          <h1 style={{ textAlign: "center" }}>FDW FORMS</h1>
        </Route>
        <Route
          exact
          path="/demo-tractor"
          render={(props) => <DemoTractorForm {...props} />}
        />
        <Route
          exact
          path="/demo-implement"
          render={(props) => <DemoImplementForm {...props} />}
        />
        <Route
          exact
          path="/inbound"
          render={(props) => <InboundForm {...props} />}
        />
        <Route
          exact
          path="/inbound-tractor"
          render={(props) => <Inboundtractor {...props} />}
        />
        <Route
          exact
          path="/inbound-implement"
          render={(props) => <InboundImplements {...props} />}
        />
        <Route
          exact
          path="/plantix"
          render={(props) => <Plantix {...props} />}
        />
        <Route
          exact
          path="/plantix-tractor"
          render={(props) => <PlantixTractor {...props} />}
        />
        <Route
          exact
          path="/plantix-implement"
          render={(props) => <PlantixImplement {...props} />}
        />
        <Route
          exact
          path="/noc"
          render={(props) => <Noc {...props} />}
        />
        <Route
          exact
          path="/noc-tractor"
          render={(props) => <NocTractor {...props} />}
        />
        <Route
          exact
          path="/noc-implement"
          render={(props) => <NocImplement {...props} />}
        />
        <Route
          exact
          path="/default"
          render={(props) => <DefaultForm {...props} />}
        />
        <Route
          exact
          path="/default-tractor"
          render={(props) => <DefaultTractor {...props} />}
        />
        <Route
          exact
          path="/default-implement"
          render={(props) => <DefaultImplement {...props} />}
        />
        <Route
          exact
          path="/leads-tractor"
          render={(props) => <LeadsTractorForm {...props} />}
        />
        <Route
          exact
          path="/leads-implement"
          render={(props) => <LeadsImplementForm {...props} />}
        />
      </Switch>
    </div>
  );
};

export default Routes;
