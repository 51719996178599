import React, { useRef, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";

function InputField({ field, form, ...other }) {
  const currentError = form.errors[field.name];
  const inputRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    if (inputRef) {
      inputRef.current.addEventListener("wheel", handleWheel);
    }

    // return () => {
    //   if (inputRef) {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     inputRef.current.removeEventListener("wheel", handleWheel);
    //   }
    // };
  }, []);

  return (
    <FormControl
      variant="outlined"
      // error={errors.actualArea && touched.actualArea ? true : false}
      error={currentError ? true : false}
    >
      <InputLabel htmlFor="outlined-implement-pin">{other.label}</InputLabel>
      <OutlinedInput
        ref={inputRef}
        id={other.id}
        type={other.type ? other.type : "number"}
        name={field.name}
        multiline={other.multiline ? true : false}
        rows={other.multiline ? 4 : 1}
        value={field.value}
        onChange={(e) => form.setFieldValue(field.name, (e.target.value).toUpperCase(), false)}
        onError={(error) => {
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        labelWidth={other.labelWidth}
      />
      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
}

export default InputField;
