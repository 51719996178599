import * as types from "../types";

export const fetchManufacturerImplementRequest = () => ({
  type: types.FETCH_IMPLEMENT_MANUFACTURER_REQUEST,
});

export const fetchManufacturerImplementSuccess = ({ manufacturer }) => ({
  type: types.FETCH_IMPLEMENT_MANUFACTURER_SUCCESS,
  payload: {
    manufacturer,
  },
});

export const fetchManufacturerImplementFailure = ({ error }) => ({
  type: types.FETCH_IMPLEMENT_MANUFACTURER_FAILURE,
  payload: {
    error: error.message,
  },
});
