import * as types from "../types";

export const addFdwEnquiryRequest = ({ requestData }) => ({
  type: types.ADD_FDW_ENQUIRY_REQUEST,
  payload: {
    requestData,
  },
});

export const addFdwEnquirySuccess = ({ res }) => ({
  type: types.ADD_FDW_ENQUIRY_SUCCESS,
  payload: {
    res,
  },
});

export const addFdwEnquiryFailure = ({ error }) => ({
  type: types.ADD_FDW_ENQUIRY_FAILURE,
  payload: {
    error: error.message,
  },
});

export const resetAddFdwEnquiryForm = () => ({
  type: types.RESET_ADD_FDW_ENQUIRY_FORM,
});
