import { takeLatest, call, put } from "redux-saga/effects";
import {
  addEnquiryFailure,
  addEnquirySuccess,
} from "../actions/addEnquiryAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncAddEnquirySaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.addEnquiry, {
      requestData: action.payload.requestData,
    });
    yield put(addEnquirySuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(addEnquiryFailure({ error: e }));
  }
}
export default function* addEnquirySaga() {
  yield takeLatest(types.ADD_ENQUIRY_REQUEST, asyncAddEnquirySaga);
}
