import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { InputField, InputAutocomplete } from "../../elements";
import { plantixFormStyles } from "./plantix.styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchMobileRequest,
  resetReactForm,
} from "../../store/actions/mobileNumberAction";

const PlantixForm = ({ ...props }) => {
  const classes = plantixFormStyles();
  const history = useHistory();
  const location = useLocation();
  let query = new URLSearchParams(location.search);
  const [reqData, setReqData] = useState("");

  useEffect(() => {
    let number = query.get("phoneHidden");
    let type = query.get("type");
    console.log(number, type);
    if (number && type) {
      let typeOfProduct = type === "Tractor Enquiry" ? "11" : "12";
      let requestData = {
        typeOfProduct,
        strMobileNo: number,
      };
      setReqData(requestData);
      props.checkNumber({ requestData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.mobileNumberResponse) {
      if (props.mobileNumberResponse.code.startsWith("4")) {
        Swal.fire({
          icon: "info",
          text: props.mobileNumberResponse.message,
        }).then((res) => {
          if (res) {
            history.push("/plantix");
          }
        });
      } else {
        if (reqData?.typeOfProduct === "11") {
          props.resetForm();
          history.push({
            pathname: "/plantix-tractor",
            state: {
              mobile: reqData.strMobileNo,
            },
          });
        } else {
          props.resetForm();
          history.push({
            pathname: "/plantix-implement",
            state: {
              mobile: reqData.strMobileNo,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mobileNumberResponse]);

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <h2>Leads Form</h2>
        <Formik
          initialValues={{
            mobileNumber: query.get("phoneHidden") ?? "",
            enquiryType: query.get("type") ?? "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.mobileNumber === "") {
              errors.mobileNumber = "*Required Field";
            }
            if (values.mobileNumber.length !== 10) {
              errors.mobileNumber = "*Mobile number is not valid";
            }
            if (values.enquiryType === "") {
              errors.enquiryType = "*Required Field";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let { mobileNumber, enquiryType } = values;
            let typeOfProduct = enquiryType === "Tractor Enquiry" ? "11" : "12";
            let requestData = {
              typeOfProduct,
              strMobileNo: mobileNumber,
            };
            setReqData(requestData);
            props.checkNumber({ requestData });
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <div className={classes.inputWrapper}>
                <h4>Mobile Number</h4>
                <Field
                  name="mobileNumber"
                  id="mobile Number"
                  label="Mobile Number"
                  labelWidth={120}
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Enquiry Type </h4>
                <Field
                  name="enquiryType"
                  id="Enquiry Type"
                  label="Enquiry Type"
                  defaultValue={values.enquiryType}
                  labelWidth={110}
                  options={[
                    {
                      label: "Tractor Enquiry",
                      value: "Tractor Enquiry",
                    },
                    {
                      label: "Implement Enquiry",
                      value: "Implement Enquiry",
                    },
                  ]}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.submitAlign}>
                <button
                  type="submit"
                  className={classes.submit}
                  // disabled={props.addImplementLoading}
                >
                  {props.mobileNumberLoading ? "Submitting.." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mobileNumberLoading: state.numberReducer.loading,
  mobileNumberResponse: state.numberReducer.res,
});

const mapDispatchToProps = (dispatch) => ({
  checkNumber: ({ requestData }) => {
    dispatch(fetchMobileRequest({ requestData }));
  },
  resetForm: () => dispatch(resetReactForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlantixForm);
