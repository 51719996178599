import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchManufacturerImplementFailure,
  fetchManufacturerImplementSuccess,
} from "../actions/fetchImplementsManufacturerAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchManufacturerImplementSaga(action) {
  try {
    yield put(clearErrors());
    let manufacturer = yield call(_api.fetchManufacturerImplement);
    yield put(fetchManufacturerImplementSuccess({ manufacturer }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchManufacturerImplementFailure({ error: e }));
  }
}
export default function* fetchManufacturerImplementSaga() {
  yield takeLatest(
    types.FETCH_IMPLEMENT_MANUFACTURER_REQUEST,
    asyncFetchManufacturerImplementSaga
  );
}
