import * as types from "../types";

export const fetchManufacturerRequest = () => ({
  type: types.FETCH_MANUFACTURER_REQUEST,
});

export const fetchManufacturerSuccess = ({ manufacturer }) => ({
  type: types.FETCH_MANUFACTURER_SUCCESS,
  payload: {
    manufacturer,
  },
});

export const fetchManufacturerFailure = ({ error }) => ({
  type: types.FETCH_MANUFACTURER_FAILURE,
  payload: {
    error: error.message,
  },
});
