import { all } from "redux-saga/effects";
import fetchTractorSaga from "./fetchTractorSaga";
import fetchImplementSaga from "./fetchImplementSaga";
import mobileNumberSaga from "./mobileNumberSaga";
import fetchManufacturerTractorSaga from "./fetchManufacturerTractorSaga";
import fetchManufacturerImplementSaga from "./fetchManufacturerImplementSaga";
import addEnquirySaga from "./addEnquirySaga";
import addFdwEnquirySaga from "./addFdwEnquirySaga";

export default function* rootSaga() {
  yield all([
    fetchTractorSaga(),
    fetchImplementSaga(),
    mobileNumberSaga(),
    fetchManufacturerTractorSaga(),
    addEnquirySaga(),
    fetchManufacturerImplementSaga(),
    addFdwEnquirySaga(),
  ]);
}
