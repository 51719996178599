import * as types from "../types";

export const fetchMobileRequest = ({ requestData }) => ({
  type: types.CHECK_MOBILE_REQUEST,
  payload: {
    requestData,
  },
});

export const fetchMobileSuccess = ({ res }) => ({
  type: types.CHECK_MOBILE_SUCCESS,
  payload: {
    res,
  },
});

export const fetchMobileFailure = ({ error }) => ({
  type: types.CHECK_MOBILE_FAILURE,
  payload: {
    error: error.message,
  },
});

export const resetReactForm = () => ({
  type: types.RESET_CHECK_MOBILE,
});
