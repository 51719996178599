import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchImplementFailure,
  fetchImplementSuccess,
} from "../actions/implementAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchImplementSaga(action) {
  try {
    yield put(clearErrors());
    let implement = yield call(_api.fetchImplements);
    // console.log("implement ", implement);
    yield put(fetchImplementSuccess({ implement }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchImplementFailure({ error: e }));
  }
}
export default function* fetchImplementSaga() {
  yield takeLatest(types.FETCH_IMPLEMENT_REQUEST, asyncFetchImplementSaga);
}
