import React from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
// import { ImplementForm, TractorForm, ReactForm } from "./screens";
import Routes from "./routes";
const theme = createMuiTheme({
  palette: {
    // type: "dark",
    primary: {
      main: "#652078",
    },
    secondary: {
      main: "#FFFFFF",
    },
    accent: {
      main: "#F37021",
    },
    light: {
      main: "#FFFFFF",
    },
    grey: {
      main: "#FAF3FF",
    },
    dark: {
      main: "#575756",
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: "'Noto Sans JP', sans-serif",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    minHeight: "100vh",
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {/* <ReactForm />
        <TractorForm /> */}
        {/* <ImplementForm /> */}
        <Routes />
        {/* <Form/> */}
      </ThemeProvider>
    </div>
  );
};

export default App;
