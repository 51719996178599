import * as types from "../types";

const initialState = {
  loading: false,
  manufacturer: null,
  error: null,
};

export default function fetchManufacturerReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MANUFACTURER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_MANUFACTURER_SUCCESS:
      return {
        ...state,
        loading: false,
        manufacturer: action.payload.manufacturer,
      };

    case types.FETCH_MANUFACTURER_FAILURE:
      return {
        ...state,
        loading: false,
        manufacturer: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
