import { takeLatest, call, put } from "redux-saga/effects";
import {
  addFdwEnquiryFailure,
  addFdwEnquirySuccess,
} from "../actions/addFdwEnquiryAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncAddFdwEnquirySaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.addFdwEnquiry, {
      requestData: action.payload.requestData,
    });
    yield put(addFdwEnquirySuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(addFdwEnquiryFailure({ error: e }));
  }
}
export default function* addFdwEnquirySaga() {
  yield takeLatest(types.ADD_FDW_ENQUIRY_REQUEST, asyncAddFdwEnquirySaga);
}
