import * as types from "../types";

export const fetchTractorRequest = () => ({
  type: types.FETCH_TRACTOR_REQUEST,
});

export const fetchTractorSuccess = ({ tractors }) => ({
  type: types.FETCH_TRACTOR_SUCCESS,
  payload: {
    tractors,
  },
});

export const fetchTractorFailure = ({ error }) => ({
  type: types.FETCH_TRACTOR_FAILURE,
  payload: {
    error: error.message,
  },
});
