import * as types from "../types";

const initialState = {
  loading: false,
  tractors: null,
  error: null,
};

export default function fetchTractorReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TRACTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_TRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        tractors: action.payload.tractors,
      };

    case types.FETCH_TRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        tractors: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
