import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchTractorFailure,
  fetchTractorSuccess,
} from "../actions/tractorAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchTractorSaga(action) {
  try {
    yield put(clearErrors());
    let tractors = yield call(_api.fetchTractors);
    yield put(fetchTractorSuccess({ tractors }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchTractorFailure({ error: e }));
  }
}
export default function* fetchTractorSaga() {
  yield takeLatest(types.FETCH_TRACTOR_REQUEST, asyncFetchTractorSaga);
}
