import * as types from "../types";

const initialState = {
  loading: false,
  implement: null,
  error: null,
};

export default function fetchImplementReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_IMPLEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_IMPLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        implement: action.payload.implement,
      };

    case types.FETCH_IMPLEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        implement: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
