import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import fetchImplementReducer from "./fetchImplementReducer";
import fetchTractorReducer from "./fetchTractorReducer";
import mobileNumberReducer from "./mobileNumberReducer";
import fetchManufacturerReducer from "./fetchManufacturerReducer";
import fetchManufacturerImplementReducer from "./fetchManufacturerImplementReducer";
import addEnquiryReducer from "./addEnquiryReducer";
import addFdwEnquiryReducer from "./addFdwEnquiryReducer";

const rootReducer = combineReducers({
  errorReducer,
  implementReducer: fetchImplementReducer,
  tractorReducer: fetchTractorReducer,
  numberReducer: mobileNumberReducer,
  manufacturerReducer: fetchManufacturerReducer,
  manufacturerImplementReducer: fetchManufacturerImplementReducer,
  enquiryReducer: addEnquiryReducer,
  fdwEnquiryReducer: addFdwEnquiryReducer,
});

export default rootReducer;
