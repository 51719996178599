import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchManufacturerFailure,
  fetchManufacturerSuccess,
} from "../actions/fetchManufacturerAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncFetchManufacturerTractorSaga(action) {
  try {
    yield put(clearErrors());
    let manufacturer = yield call(_api.fetchManufacturerTractor);
    yield put(fetchManufacturerSuccess({ manufacturer }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchManufacturerFailure({ error: e }));
  }
}
export default function* fetchManufacturerTractorSaga() {
  yield takeLatest(
    types.FETCH_MANUFACTURER_REQUEST,
    asyncFetchManufacturerTractorSaga
  );
}
