import axios from "axios";
import qs from "qs";
import { API_ROUTES, API_BASE_URL } from "../config";

export const fetchTractors = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.FDW_TRACTOTS}`,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchImplements = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.FDW_IMPLEMENTS}`,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const mobileNumber = async ({ requestData }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.MOBILE_CHECK}`,
      data: requestData,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchManufacturerTractor = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.COMP_TRACTOR}`,
    });
    return res.data;
  } catch (error) {
    console.err(error);
    throw error;
  }
};

export const fetchManufacturerImplement = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.COMP_IMPLEMENTS}`,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addEnquiry = async ({ requestData }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.DEMOPLOT_URL}${API_ROUTES.ADD_SUBMIT}`,
      data: qs.stringify(requestData),
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const addFdwEnquiry = async ({ requestData }) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${API_BASE_URL.CRM_URL}${API_ROUTES.FDW_SUBMIT}`,
      data: requestData,
    });
    return data;
  } catch (error) {
    console.err(error);
    throw error;
  }
};
