export const API_ROUTES = {
  FDW_TRACTOTS: "/get_fdw_tractors/",
  FDW_IMPLEMENTS: "/get_fdw_implement/",
  MOBILE_CHECK: "/fdw_enquiry_check/",
  COMP_TRACTOR: "/fdw_competitor_tractors/",
  COMP_IMPLEMENTS: "/fdw_competitor_implements/",
  ADD_SUBMIT: "/campaign/submit_fdw_data/",
  FDW_SUBMIT: "/fdw_submit/",
};

export const API_BASE_URL = {
  CRM_URL: process.env.REACT_APP_CRM_URL
    ? process.env.REACT_APP_CRM_URL
    : "https://crm-fastapi.azurewebsites.net",
  DEMOPLOT_URL: process.env.REACT_APP_DEMOPLOT_URL
    ? process.env.REACT_APP_DEMOPLOT_URL
    : "https://crop-advisory.azurewebsites.net",
};
