import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function InputAutocomplete({ field, form, options, ...other }) {
  const currentError = form.errors[field.name];

  return (
    <Autocomplete
      //   freeSolo
      id={other.id}
      disableClearable
      options={options}
      getOptionLabel={(option) => option?.label ?? option?.value}
      defaultValue={
        other.defaultValue
          ? options.find(
              (v) =>
                v?.label === other.defaultValue ||
                v?.value === other.defaultValue
            )
          : null
      }
      disabled={other?.disabled ?? false}
      inputValue={field.value}
      onInputChange={(e, newValue) => {
        // console.log(newValue);
        form.setFieldValue(field.name, newValue, false);
      }}
      style={{
        flex: 1,
        width: "100%",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={other.label}
          variant="outlined"
          name={field.name}
          value={field.value}
          error={currentError ? true : false}
          helperText={currentError ? currentError : ""}
          InputProps={{ ...params.InputProps, type: "search" }}
        />
      )}
    />
  );
}

export default InputAutocomplete;
