// ERROR TYPES
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//FETCH TRACTOR
export const FETCH_TRACTOR_REQUEST = "FETCH_TRACTOR_REQUEST";
export const FETCH_TRACTOR_SUCCESS = "FETCH_TRACTOR_SUCCESS";
export const FETCH_TRACTOR_FAILURE = "FETCH_TRACTOR_FAILURE";

//FETCH IMPLEMENTS
export const FETCH_IMPLEMENT_REQUEST = "FETCH_IMPLEMENT_REQUEST";
export const FETCH_IMPLEMENT_SUCCESS = "FETCH_IMPLEMENT_SUCCESS";
export const FETCH_IMPLEMENT_FAILURE = "FETCH_IMPLEMENT_FAILURE";

//CHECK MOBILE NUMBER
export const CHECK_MOBILE_REQUEST = "CHECK_MOBILE_REQUEST";
export const CHECK_MOBILE_SUCCESS = "CHECK_MOBILE_SUCCESS";
export const CHECK_MOBILE_FAILURE = "CHECK_MOBILE_FAILURE";
export const RESET_CHECK_MOBILE = "RESET_CHECK_MOBILE";

//FETCH TRACTOR MANUFACTURERS
export const FETCH_MANUFACTURER_REQUEST = "FETCH_MANUFACTURER_REQUEST";
export const FETCH_MANUFACTURER_SUCCESS = "FETCH_MANUFACTURER_SUCCESS";
export const FETCH_MANUFACTURER_FAILURE = "FETCH_MANUFACTURER_FAILURE";

//FETCH IMPLEMENT MANUFACTURERS
export const FETCH_IMPLEMENT_MANUFACTURER_REQUEST =
  "FETCH_IMPLEMENT_MANUFACTURER_REQUEST";
export const FETCH_IMPLEMENT_MANUFACTURER_SUCCESS =
  "FETCH_IMPLEMENT_MANUFACTURER_SUCCESS";
export const FETCH_IMPLEMENT_MANUFACTURER_FAILURE =
  "FETCH_IMPLEMENT_MANUFACTURER_FAILURE";

//ADD ENQUIRY
export const ADD_ENQUIRY_REQUEST = "ADD_ENQUIRY_REQUEST";
export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_FAILURE = "ADD_ENQUIRY_FAILURE";
export const RESET_ADD_ENQUIRY_FORM = "RESET_ADD_ENQUIRY_FORM";

//ADD FDW ENQUIRY
export const ADD_FDW_ENQUIRY_REQUEST = "ADD_FDW_ENQUIRY_REQUEST";
export const ADD_FDW_ENQUIRY_SUCCESS = "ADD_FDW_ENQUIRY_SUCCESS";
export const ADD_FDW_ENQUIRY_FAILURE = "ADD_FDW_ENQUIRY_FAILURE";
export const RESET_ADD_FDW_ENQUIRY_FORM = "RESET_ADD_FDW_ENQUIRY_FORM";
