import * as types from "../types";

const initialState = {
  loading: false,
  requestData: null,
  res: null,
  error: null,
};

export default function addEnquiryReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.payload.requestData,
      };

    case types.ADD_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.payload.res,
      };

    case types.ADD_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        requestData: null,
        res: null,
        error: action.payload.error,
      };

    case types.RESET_ADD_ENQUIRY_FORM:
      return {
        ...state,
        loading: false,
        requestData: null,
        res: null,
        error: null,
      };

    default:
      return state;
  }
}
