import * as types from "../types";

export const fetchImplementRequest = () => ({
  type: types.FETCH_IMPLEMENT_REQUEST,
});

export const fetchImplementSuccess = ({ implement }) => ({
  type: types.FETCH_IMPLEMENT_SUCCESS,
  payload: {
    implement,
  },
});

export const fetchImplementFailure = ({ error }) => ({
  type: types.FETCH_IMPLEMENT_FAILURE,
  payload: {
    error: error.message,
  },
});
