import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";
import data from "../../data/villages.json";
import crops from "../../data/crop.json";
import Swal from "sweetalert2";
import { InputField, DatePicker, InputAutocomplete } from "../../elements";
import { inboundImplementStyles } from "./inboundImplement.styles";
import { fetchImplementRequest } from "../../store/actions/implementAction";
import { fetchManufacturerImplementRequest } from "../../store/actions/fetchImplementsManufacturerAction";
import { connect } from "react-redux";
import { formatDateInYYMMDD } from "../../utils";
import {
  addFdwEnquiryRequest,
  resetAddFdwEnquiryForm,
} from "../../store/actions/addFdwEnquiryAction";

const SetData = ({
  params,
  setDistrictArr,
  setTalukaArr,
  districtArr,
  talukaArr,
  setVillageArr,
  newData,
  manufacturers,
  models,
  setModelOptions,
}) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (params.state) {
      let states = Object.keys(data);
      let foundState = states.find(
        (item) =>
          item.toLowerCase().trim() === params.state.toLowerCase().trim()
      );
      if (foundState) {
        setFieldValue("state", foundState, false);
      }
    }
  }, []);

  useEffect(() => {
    let districts = null;
    let foundDistrict = null;
    if (data && Object.keys(data).includes(values.state)) {
      districts = Object.keys(data[values.state]);
      const districtList = districts.map((district) => ({
        value: district,
      }));
      setDistrictArr(districtList);
    }
    if (districts && params.district) {
      foundDistrict = districts.find(
        (item) =>
          item.toLowerCase().trim() === params.district.toLowerCase().trim()
      );
    }
    if (foundDistrict) {
      setFieldValue("district", foundDistrict, false);
    } else {
      setFieldValue("district", "", false);
    }

    setFieldValue("taluka", "", false);
    setFieldValue("village", "", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.state]);

  useEffect(() => {
    let talukas = null,
      foundTaluka = null;
    if (
      data &&
      Object.keys(data).includes(values.state) &&
      districtArr.find((d) => d.value === values.district)
    ) {
      talukas = Object.keys(data[values.state][values.district]);
      const talukaList = talukas.map((taluka) => ({
        value: taluka,
      }));

      setTalukaArr(talukaList);
    }
    if (talukas && params.taluka) {
      foundTaluka = talukas.find(
        (item) =>
          item.toLowerCase().trim() === params.taluka.toLowerCase().trim()
      );
    }
    if (foundTaluka) {
      setFieldValue("tehsil", foundTaluka, false);
    } else {
      setFieldValue("tehsil", "", false);
    }
    setFieldValue("village", "", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.district]);

  useEffect(() => {
    let villages = null,
      foundVillage = null;
    if (
      data &&
      Object.keys(data).includes(values.state) &&
      districtArr.find((d) => d.value === values.district) &&
      talukaArr.find((d) => d.value === values.tehsil)
    ) {
      villages = data[values.state][values.district][values.tehsil];
      const villageList = villages.map((village) => ({
        value: village,
      }));

      setVillageArr(villageList);
    }
    if (villages && params.village) {
      foundVillage = villages.find(
        (item) =>
          item.toLowerCase().trim() === params.village.toLowerCase().trim()
      );
    }
    if (foundVillage) {
      setFieldValue("village", foundVillage, false);
    } else {
      setFieldValue("village", "", false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.tehsil]);

  useEffect(() => {
    if (
      newData &&
      manufacturers.find((item) => item.value === values.manufacturer)
    ) {
      let filteredData = newData.filter(
        (item) => item.manufacturer === values.manufacturer
      );
      let filteredModel = filteredData.map((item) => item.model);
      filteredModel = filteredModel.filter((item) => item);
      filteredModel = [...new Set(filteredModel)];
      filteredModel = filteredModel.map((item) => ({
        value: item,
        // value: item == null ? "" : item,
      }));
      setModelOptions(filteredModel);
      setFieldValue("model", "", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.manufacturer]);

  return null;
};

const InboundImplements = ({ ...props }) => {
  const classes = inboundImplementStyles();
  const location = useLocation();
  const history = useHistory();

  const [implementOptions, setImplementOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [districtArr, setDistrictArr] = useState([]);
  const [talukaArr, setTalukaArr] = useState([]);
  const [villageArr, setVillageArr] = useState([]);
  const [cropsData, setCropsData] = useState([]);

  useEffect(() => {
    if (!props.implement) {
      props.getImplements();
    }
    if (!props.manufacturers) {
      props.getManufacturers();
    }
    const stateValue = Object.keys(data);
    const stateList = stateValue.map((state) => ({
      value: state,
    }));
    setStateArr(stateList);

    const cropValue = Object.values(crops.crop_arr);
    const unique = [...new Set(cropValue)];
    const cropList = unique.map((crop) => ({
      value: crop,
    }));
    cropList.sort((a, b) => (a.value > b.value ? 1 : -1));
    setCropsData(cropList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatLabel(str) {
    str = str.split("-");
    str.shift();
    str = str.join("-").trim();

    return str;
  }

  useEffect(() => {
    if (props.implement) {
      let list = props.implement.map((implement_) => ({
        label: formatLabel(implement_.implement_name),
        value: implement_.implement_name,
      }));
      list.sort((a, b) => (a.value > b.value ? 1 : -1));
      setImplementOptions(list);
    }
  }, [props.implement]);

  useEffect(() => {
    if (props.manufacturers) {
      let uniqueManufacturerList = [
        ...new Set(props.manufacturers.map((item) => item.manufacturer)),
      ];
      uniqueManufacturerList = uniqueManufacturerList.map((item) => ({
        value: item,
      }));
      uniqueManufacturerList.sort((a, b) => (a.value > b.value ? 1 : -1));
      setManufacturerOptions(uniqueManufacturerList);
    }
  }, [props.manufacturers]);

  useEffect(() => {
    if (props.enquiryResponse) {
      Swal.fire({
        icon: props.enquiryResponse.status.toLowerCase(),
        text: props.enquiryResponse.message,
      }).then((res) => {
        if (res) {
          props.resetSubmitEnquiry();
          history.push("/inbound");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.enquiryResponse]);

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <h2>Implements Enquiry</h2>
        <Formik
          initialValues={{
            customerType: "",
            conpanyName: "",
            date: new Date(),
            salutation: "",
            firstName: location?.state?.name ?? "",
            lastName: "",
            fathersName: "",
            expectedDeliveryDate: new Date(),
            visited: "",
            firstVisitDate: new Date(),
            mobileNumber: location?.state?.mobile ?? "",
            preferredAddress: "",
            address: "",
            village: "",
            tehsil: "",
            district: "",
            state: "",
            pincode: "",
            alreadyOwnImplement: "",
            ownImplementbuyerFalse: "FTB",
            ownImplementbuyerTrue: "Additional New",
            buyer: "",
            selectedImplement: "",
            irrigatedLand: "10",
            cropsV: "",
            farmingLand: "10",
            nonIrrigated: "",
            model: "",
            manufacturer: "",
            purchaseYear: "",
            modeOfFinance: "004 TBD",
            sourceOfEnquiry: "003 Referral",
            subSource: "Krish-e",
            originSource: location?.state?.originSource ?? "Inbound",
          }}
          validate={(values) => {
            const errors = {};
            if (values.firstName === "") {
              errors.firstName = "*Required Field";
            }
            if (values.lastName === "") {
              errors.lastName = "*Required Field";
            }
            if (values.fathersName === "") {
              errors.fathersName = "*Required Field";
            }
            if (values.expectedDeliveryDate === "") {
              errors.expectedDeliveryDate = "*Required Field";
            }
            if (values.address === "") {
              errors.address = "*Required Field";
            }
            if (values.village === "") {
              errors.village = "*Required Field";
            }
            if (values.tehsil === "") {
              errors.tehsil = "*Required Field";
            }
            if (values.district === "") {
              errors.district = "*Required Field";
            }
            if (values.state === "") {
              errors.state = "*Required Field";
            }
            if (values.pincode === "") {
              errors.pincode = "*Required Field";
            }
            if (values.alreadyOwnImplement === "") {
              errors.buyer = "*Required Field";
            }
            if (values.irrigatedLand === "") {
              errors.irrigatedLand = "*Required Field";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let {
              customerType,
              companyName,
              salutation,
              firstName,
              lastName,
              fathersName,
              expectedDeliveryDate,
              firstVisitDate,
              mobileNumber,
              address,
              village,
              tehsil,
              district,
              state,
              pincode,
              selectedImplement,
              alreadyOwnImplement,
              farmingLand,
              cropsV,
              irrigatedLand,
              model,
              manufacturer,
              purchaseYear,
              modeOfFinance,
              sourceOfEnquiry,
              subSource,
              originSource,
            } = values;
            let expectedDate = formatDateInYYMMDD(expectedDeliveryDate);
            let visitDate = formatDateInYYMMDD(firstVisitDate);
            let preferredAddress;
            let customer =
              customerType === "Individual" ? "001 Individual" : "002 Company";
            let salu;
            if (salutation === "MR") {
              salu = "31 MR";
            } else if (salutation === "MRS") {
              salu = "33 MRS";
            } else {
              salu = " 35 MS";
            }
            let buyerType;

            if (alreadyOwnImplement === "No") {
              buyerType = "001 FTB";
            } else if (alreadyOwnImplement === "Yes") {
              buyerType = "002 Additional New";
            }

            let nonIrrigatedLand = farmingLand - irrigatedLand;
            if (values.customerType === "Individual") {
              preferredAddress = "Residential";
            } else {
              preferredAddress = "Office";
            }

            let implementName = implementOptions.find(
              (imp) => imp.label === selectedImplement
            );
            implementName = implementName.value ?? selectedImplement;

            let manufacturerCode;
            let modelCode;
            let manufacturerData = props.manufacturers.filter(
              (item) =>
                item.manufacturer === manufacturer &&
                (!item.model || item.model === model)
            );
            if (manufacturerData.length > 0) {
              manufacturerCode = manufacturerData[0]?.manufacturer_code ?? "";
              modelCode = manufacturerData[0]?.model_code ?? "";
            }

            let requestData = {
              typeOfProduct: "12",
              originSource,
              strCustomerType: customer,
              strCompanyName: companyName,
              strSalutation: salu,
              strFirstName: firstName,
              strLastName: lastName,
              strFathersName: fathersName,
              strExpectedDeliverydate: expectedDate,
              strFirstVisitDate: visitDate,
              strMobileNo: mobileNumber,
              strPreferredAddress: preferredAddress,
              strAddress: address,
              strVillage: village,
              strTehsil: tehsil,
              strDistrict: district,
              strState: state,
              strPincode: pincode,
              strTypeofBuyer: buyerType,
              strModeofFinance: modeOfFinance,
              strSourceofEnquiry: sourceOfEnquiry,
              strSubSourceofEnquiry: subSource,
              strImplIntrestedModel: implementName,
              strIrrigatedLand: irrigatedLand,
              strNonirrigatedland: nonIrrigatedLand,
              strCrop: cropsV,
              strExistsManufacture: manufacturerCode ?? "",
              strExistsModel: modelCode ?? "",
              strExistsYop: purchaseYear,
            };
            props.submitEnquiry({ requestData });
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <div className={classes.inputWrapper}>
                <h4>Customer Type </h4>
                <Field
                  name="customerType"
                  id="customer-type"
                  label="Customer Type"
                  defaultValue="Individual"
                  labelWidth={110}
                  options={[
                    {
                      label: "Individual",
                      value: "Individual",
                    },
                    {
                      label: "Company",
                      value: "Company",
                    },
                  ]}
                  component={InputAutocomplete}
                />
              </div>
              {values.customerType === "Company" && (
                <div className={classes.inputWrapper}>
                  <h4>Company Name</h4>
                  <Field
                    name="companyName"
                    id="Company-name"
                    label="Company Name"
                    labelWidth={120}
                    type="text"
                    component={InputField}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <h4>Salutation </h4>
                <Field
                  name="salutation"
                  id="Salutation"
                  label="Salutation"
                  defaultValue="MR"
                  labelWidth={110}
                  options={[
                    {
                      label: "MR",
                      value: "MR",
                    },
                    {
                      label: "MRS",
                      value: "MRS",
                    },
                    {
                      label: "MS",
                      value: "MS",
                    },
                  ]}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>First Name</h4>
                <Field
                  name="firstName"
                  id="first-name"
                  label="First Name"
                  labelWidth={85}
                  type="text"
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Last Name</h4>
                <Field
                  name="lastName"
                  id="last-name"
                  label="Last Name"
                  labelWidth={80}
                  type="text"
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Father's Name</h4>
                <Field
                  name="fathersName"
                  id="fathers Name"
                  label="Father's Name"
                  labelWidth={110}
                  type="text"
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Expected Delivery Date</h4>
                <Field name="expectedDeliveryDate" component={DatePicker} />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Have you visited dealership before?</h4>
                <Field
                  name="visited"
                  id="visited-type"
                  label="Have you visited dealership before"
                  defaultValue="No"
                  labelWidth={250}
                  options={[
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                  component={InputAutocomplete}
                />
              </div>
              {values.visited === "Yes" && (
                <div className={classes.inputWrapper}>
                  <h4>First Visit Date</h4>
                  <Field name="firstVisitDate" component={DatePicker} />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <h4>Mobile Number</h4>
                <Field
                  name="mobileNumber"
                  id="mobile Number"
                  label="Mobile Number"
                  labelWidth={120}
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>State</h4>
                <Field
                  name="state"
                  id="stateName"
                  label="State"
                  labelWidth={40}
                  options={stateArr}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>District</h4>
                <Field
                  name="district"
                  id="DistrictName"
                  label="District"
                  labelWidth={60}
                  options={districtArr}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Tehsil</h4>
                <Field
                  name="tehsil"
                  id="TehsilName"
                  label="Tehsil Name"
                  labelWidth={100}
                  options={talukaArr}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Village</h4>
                <Field
                  name="village"
                  id="villageName"
                  label="Village"
                  labelWidth={50}
                  // type="text"
                  options={villageArr}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Pincode</h4>
                <Field
                  name="pincode"
                  id="Pincode"
                  label="Pincode"
                  labelWidth={60}
                  type="number"
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Landmark</h4>
                <Field
                  name="address"
                  id="full-address"
                  label="Landmark"
                  labelWidth={70}
                  multiline
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Do you already own implement?</h4>
                <Field
                  name="alreadyOwnImplement"
                  id="already-own-implement"
                  label="Do you already own implement "
                  labelWidth={260}
                  options={[
                    {
                      label: "Yes",
                      value: "Yes",
                    },
                    {
                      label: "No",
                      value: "No",
                    },
                  ]}
                  component={InputAutocomplete}
                />
              </div>
              {values.alreadyOwnImplement === "Yes" && (
                <>
                  <div className={classes.inputWrapper}>
                    <h4>Manufacturer of the last implement purchased</h4>
                    <Field
                      name="manufacturer"
                      id="manufacturerName"
                      label="Manufacturer of the last implement purchased"
                      labelWidth={100}
                      // type="text"
                      options={manufacturerOptions}
                      component={InputAutocomplete}
                    />
                  </div>
                  {modelOptions && modelOptions.length > 0 && (
                    <div className={classes.inputWrapper}>
                      <h4>Model of the last implement purchased</h4>
                      <Field
                        name="model"
                        id="modelName"
                        label="Model of the last implement purchased"
                        labelWidth={40}
                        // type="text"
                        options={modelOptions}
                        component={InputAutocomplete}
                      />
                    </div>
                  )}

                  <div className={classes.inputWrapper}>
                    <h4>Year of last implement Purchase</h4>
                    <Field
                      name="purchaseYear"
                      id="purchase"
                      label="Year of last implement Purchase"
                      labelWidth={240}
                      type="number"
                      component={InputField}
                    />
                  </div>
                </>
              )}
              <div className={classes.inputWrapper}>
                <h4>Implements</h4>
                <Field
                  name="selectedImplement"
                  id="implements"
                  label="Implements"
                  labelWidth={120}
                  options={implementOptions}
                  component={InputAutocomplete}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Total Land Owned for Farming</h4>
                <Field
                  name="farmingLand"
                  id="farming-land"
                  label="Total Land Owned for Farming"
                  labelWidth={250}
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Irrigated Land (In Acres)</h4>
                <Field
                  name="irrigatedLand"
                  id="irrigated"
                  label="Irrigated Land"
                  labelWidth={100}
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <h4>Crops</h4>
                <Field
                  name="cropsV"
                  id="cropName"
                  label="Crops"
                  labelWidth={40}
                  // type="text"
                  options={cropsData}
                  component={InputAutocomplete}
                />
              </div>
              <SetData
                districtArr={districtArr}
                talukaArr={talukaArr}
                setDistrictArr={setDistrictArr}
                setTalukaArr={setTalukaArr}
                setVillageArr={setVillageArr}
                newData={props?.manufacturers}
                manufacturers={manufacturerOptions}
                models={modelOptions}
                setModelOptions={setModelOptions}
                params={location.state}
              />
              <div className={classes.submitAlign}>
                <button
                  type="submit"
                  className={classes.submit}
                  // disabled={props.enquiryLoading}
                >
                  {props.enquiryLoading ? "Submitting.." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  implement: state.implementReducer.implement,
  implementLoading: state.implementReducer.loading,
  manufacturers: state.manufacturerImplementReducer.manufacturer,
  manufacturerLoading: state.manufacturerImplementReducer.loading,
  enquiryLoading: state.fdwEnquiryReducer.loading,
  enquiryResponse: state.fdwEnquiryReducer.res,
});
const mapDispatchToProps = (dispatch) => ({
  getImplements: () => dispatch(fetchImplementRequest()),
  getManufacturers: () => dispatch(fetchManufacturerImplementRequest()),
  submitEnquiry: ({ requestData }) =>
    dispatch(addFdwEnquiryRequest({ requestData })),
  resetSubmitEnquiry: () => dispatch(resetAddFdwEnquiryForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InboundImplements);
