import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchMobileFailure,
  fetchMobileSuccess,
} from "../actions/mobileNumberAction";
import { returnErrors, clearErrors } from "../actions/errorAction";
import * as _api from "../../api";
import * as types from "../types";

function* asyncMobileNumberSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.mobileNumber, {
      requestData: action.payload.requestData,
    });
    yield put(fetchMobileSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(fetchMobileFailure({ error: e }));
  }
}
export default function* mobileNumberSaga() {
  yield takeLatest(types.CHECK_MOBILE_REQUEST, asyncMobileNumberSaga);
}
