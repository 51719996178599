import { makeStyles } from "@material-ui/core/styles";

export const inboundImplementStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: theme.palette.light.main,
    padding: "0 2em",
  },
  contentWrapper: {
    maxWidth: "500px",
    height: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    gap: "0.5em",
    padding: "3em 0",
    "& > h5": {
      cursor: "pointer",
    },
    "& > h2": {
      textAlign: "center",
    },
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "1em",
  },
  inputWrapper: {
    flex: 1,
    gap: "2em",
    minWidth: "200px",
    padding: "0.5em 0",
    "& > h4": {
      padding: "1em 0",
    },
  },
  submitAlign: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "3em",
  },
  submit: {
    width: 170,
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    margin: "1.3em 0 0 0",
    textTransform: "capitalize",
    cursor: "pointer",
    border: "none",
    outline: "none",
  },
}));
